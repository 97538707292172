<template>
  <div>
    <v-card-text>
      <v-container class="row1">
        <v-row>
          <v-col cols="14" sm="8" md="8">
            <v-row dense class="mb-5">
              <router-link
                v-bind:to="'/assets-category/'"
                style="text-decoration: none"
              >
                <v-btn rounded color="#05a2fa">
                  <v-icon color="white"> mdi-arrow-left </v-icon>
                </v-btn>
              </router-link>
            </v-row>
          </v-col>

          <v-col cols="14" sm="8" md="4">
            <v-row dense justify="end" class="mb-5">
              <div>
                <v-btn rounded color="#05a2fa" dark @click="dialog = true">
                  <v-icon color="white"> mdi-plus-circle </v-icon>
                  <v-card-text> Add a New Type </v-card-text>
                </v-btn>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row>
          <v-col cols="14" sm="8" md="8" class="itemsLayout">
            <v-row dense>
              <v-col cols="3" class="pb-3">
                <v-card height="200" width="150" @click="dialog = true">
                  <v-card-text class="text-center">
                    <v-icon
                      color="#05a2fa"
                      style="font-size: 70px"
                      class="mt-11"
                    >
                      mdi-plus
                    </v-icon>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col
                v-for="card in newItems"
                :key="card.assetTypeId"
                :cols="3"
                class="pb-3"
              >
                <router-link
                  v-bind:to="{
                    path: '/assets-category/assets-type/' + card.assetTypeId,
                  }"
                  style="text-decoration: none"
                >
                  <v-card height="200" width="150" style="cursor: pointer">
                    <v-img
                      :src="`https://cdn.vuetifyjs.com/images/cards/house.jpg`"
                      class="white--text align-end"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                      width="150"
                      height="100"
                    >
                      <v-card-title style="height: 40px">
                        <div class="headerClass">
                          <v-icon
                            style="font-size: 20px; color: #05a2fa"
                            color="#ffffffff"
                            class="mb-1"
                          >
                            mdi-format-list-bulleted-type
                          </v-icon>

                          {{ card.assetTypeName }}
                        </div>
                      </v-card-title>
                    </v-img>

                    <v-card-text>
                      <div class="headerClass">Rent : $.{{ card.rent }}</div>

                      <div style="font-size: 12px" class="headerClass">
                        <v-icon style="font-size: 20px" class="mb-1">
                          mdi-barcode
                        </v-icon>
                        {{ card.ean }}
                      </div>

                      <div class="headerClass">
                        {{ card.description }}
                      </div>
                    </v-card-text>
                  </v-card>
                </router-link>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="4" md="4">
            <v-card>
              <div style="float: right; margin-top: 10px; margin-right: 10px">
                <v-icon
                  color="#05a2fa"
                  @click="editItem()"
                  style="margin-right: 5px"
                >
                  mdi-pencil-outline
                </v-icon>
                <v-icon color="#FF0000" @click="deleteItem()">
                  mdi-trash-can-outline
                </v-icon>
              </div>

              <!-- <v-card-title>
                Category Id : {{ categoryInfo.assetCategoryId }}
              </v-card-title> -->
              <v-card-title style="font-size: 20px">
                Category Name : {{ categoryInfo.assetCategoryName }}
              </v-card-title>
              <v-divider class="mx-4"></v-divider>
              <v-card-text>
                Description : {{ categoryInfo.description }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-col cols="14" sm="8" md="8" style="margin-top: -25px">
        <v-pagination
          v-model="page"
          :length="pageCount"
          @click="changePage"
          circle
          color="#05a2fa"
        ></v-pagination>
      </v-col>
      <v-container @click="dialogTrash = true" style="cursor: pointer">
        <v-row>
          <v-col cols="14" sm="8" md="8">
            <v-card>
              <v-card-text class="text-center" style="font-size: 15px">
                <v-icon color="#05a2fa" style="font-size: 25px">
                  mdi-delete-empty
                </v-icon>
                Trash
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Add New Asset Type</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="typesForm">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.assetTypeName"
                      label="Asset Type Name"
                      outlined
                      clearable
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.description"
                      label="Description"
                      outlined
                      clearable
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.rent"
                      type="number"
                      class="inputNumber"
                      label="Rent"
                      outlined
                      clearable
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.ean"
                      type="number"
                      class="inputNumber"
                      label="EAN"
                      maxlength="13"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      outlined
                      clearable
                    />
                  </v-col>
                  <!-- <v-col cols="12">
                    <v-select
                      v-model="editedItem.assetCategoryId"
                      :items="categoryInfo"
                      item-text="assetCategoryName"
                      item-value="assetCategoryId"
                      label="Asset Category Id"
                    />
                  </v-col> -->
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
            <v-btn color="blue darken-1" text @click="validate"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogEdit" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Edit Categogry </span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="categoriesForm">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedCategoryInfo.assetCategoryName"
                      label="Category Name"
                      outlined
                      clearable
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedCategoryInfo.description"
                      label="Category Description"
                      outlined
                      clearable
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="dialogEdit = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="saveEdit"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline">
            Are you sure you want to delete this item?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="closeDelete">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="trashItem"> OK </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogTrash" max-width="1200">
        <v-card class="pa-10">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          >
          </v-text-field>
          <v-data-table :headers="headers" :items="trashed" :search="search">
            <template v-slot:[`item.restore`]="{ item }">
              <v-icon color="#05a2fa" @click="restoreItem(item)">
                mdi-restore
              </v-icon>
            </template>
            <template v-slot:[`item.delete`]="{ item }">
              <v-icon color="#FF0000" @click="permanentDelete(item)">
                mdi-trash-can-outline
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogRestore" max-width="500px">
        <v-card>
          <v-card-title class="headline">
            Are you sure you want to restore this item?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="dialogRestore = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="restoreItemConfm">
              OK
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogPermanentDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline">
            Are you sure you want to delete this item permanently?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="closeDelete">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm">
              OK
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </div>
</template>

<style>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
.itemsLayout {
  height: 450px;
  overflow: hidden;
  overflow-y: auto;
}
.inputNumber input[type="number"] {
  -moz-appearance: textfield;
}
.inputNumber input::-webkit-outer-spin-button,
.inputNumber input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>

<script>
export default {
  data() {
    return {
      dialog: false,
      dialogEdit: false,
      dialogDelete: false,
      dialogRestore: false,
      dialogTrash: false,
      dialogPermanentDelete: false,
      search: "",
      headers: [
        { text: "assetTypeId", value: "assetTypeId" },
        { text: "assetTypeName", value: "assetTypeName" },
        { text: "description", value: "description" },
        { text: "rent", value: "rent" },
        { text: "ean", value: "ean" },
        { text: "assetCategoryId", value: "assetCategoryId" },
        { text: "", value: "restore" },
        { text: "", value: "delete" },
      ],
      assetsTypes: [],
      categoryInfo: {},
      editedCategoryInfo: {
        categoryName: "",
        description: "",
      },

      defaultCategoryInfo: {},

      clubs: [],
      categories: [],
      locations: [],
      status: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      stockStatus: [
        { text: "Yes", value: false },
        { text: "No", value: true },
      ],
      editedIndex: -1,

      editedItem: {
        assetTypeName: "",
        description: "",
        rent: "",
        ean: "",
        assetCategoryId: "",
      },
      defaultItem: {
        assetTypeName: "",
        description: "",
        rent: "",
        ean: "",
        assetCategoryId: "",
      },
      trashed: [],
      assetCategoryId: "",
      page: 1,
      pageCount: null,
      start: 0,
      end: 7,
      newItems: [],
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add a New Product" : "Edit Product";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    page: function () {
      this.changePage();
    },
  },

  created() {
    this.getDataFromApi();
  },

  methods: {
    getDataFromApi() {
      var currentUrl = window.location.pathname;
      let vars = currentUrl.split("/");
      vars.reverse();
      let url = vars[0];
      url = parseInt(url);

      this.assetCategoryId = url;
      this.editedItem.assetCategoryId = url;

      this.$http.get(`/AssetCategory/${url}`).then((response) => {
        this.categoryInfo = response.data.data;
      });
      this.$http
        .get(
          `/AssetType/GetAllByCategoryId?AssetCategoryId=${this.editedItem.assetCategoryId}`
        )
        .then((response) => {
          this.assetsTypes = response.data.data;
          this.changePage();
        });

      this.$http
        .get(`/AssetType/GetAllTrashedByCategoryId?AssetCategoryId=${url}`)
        .then((response) => {
          this.trashed = response.data.data;
        });
    },
    updateData() {
      this.page = 1;
      this.changePage();
    },

    changePage() {
      var count = this.assetsTypes.length;
      var float = count / this.end;
      var int = parseInt(count / this.end);
      if (float > int) {
        this.pageCount = int + 1;
      } else {
        this.pageCount = int;
      }
      var diff = this.page - 1;
      var newStart = this.start + this.end * diff;
      var newEnd = this.end + this.end * diff;

      const items = this.assetsTypes.slice(newStart, newEnd);
      this.newItems = items;
      console.log(this.newItems);
    },
    editItem() {
      this.editedCategoryInfo = Object.assign({}, this.categoryInfo);
      this.dialogEdit = true;
    },

    deleteItem() {
      this.dialogDelete = true;
    },
    trashItem() {
      console.log("done");
      var Id = this.categoryInfo.assetCategoryId;
      this.$http
        .post("/AssetCategory/Trash?Id=" + Id)
        .then((response) => {
          if (response.data.isSuccess === true) {
            this.$toast.success("Deleted successfully.", "Success", {
              position: "topRight",
            });
            this.$router.push("/assets-category/");
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          if (error.response.status) {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        });
    },
    restoreItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogRestore = true;
    },
    permanentDelete(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogPermanentDelete = true;
    },
    restoreItemConfm() {
      var id = this.editedItem.assetTypeId;
      console.log(this.editedItem);
      this.$http
        .post(`/AssetType/Restore?Id=${id}`)
        .then((response) => {
          if (response.data.success === true) {
            this.trashed.shift(response.data.data);
            this.assetsTypes.unshift(this.editedItem);

            this.updateData();
            this.$toast.success("Restore successfully.", "Success", {
              position: "topRight",
            });
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          if (error.response.status) {
            this.$toast.error(" Restore Process Failed", "Error", {
              position: "topRight",
            });
          }
        });

      this.dialogRestore = false;
    },
    deleteItemConfirm() {
      var Id = this.editedItem.assetCategoryId;
      this.$http
        .delete("/AssetType/" + Id)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success(
              "Asset Category deleted successfully." + response.status,
              "Success",
              {
                position: "topRight",
              }
            );
            this.trashed.shift(response.data.data);
          }
        })

        .catch((error) => {
          if (error.response.status === 404) {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        });
      //this.users.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.dialogEdit = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItem.assetCategoryId = this.assetCategoryId;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.dialogPermanentDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    validate() {
      if (
        this.editedItem.assetTypeName &&
        this.editedItem.description &&
        this.editedItem.rent &&
        this.editedItem.ean
      ) {
        if (this.editedItem.ean.length >= 13) {
          this.save();
        } else {
          this.$toast.error("Enter a Valid EAN", "Error", {
            position: "topRight",
          });
        }
      } else {
        this.$toast.error("Please Fill the Form", "Error", {
          position: "topRight",
        });
      }
    },
    save() {
      this.$http
        .post("/AssetType", this.editedItem)
        .then((response) => {
          if (response.data.isSuccess === true) {
            this.assetsTypes.unshift(response.data.outputObject);
            this.updateData();

            this.$toast.success("Asset Type added successfully.", "Success", {
              position: "topRight",
            });
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          if (error.response.status) {
            this.$toast.error("Adding Process Failed", "Error", {
              position: "topRight",
            });
          }
        });

      this.close();
    },

    saveEdit() {
      const data = this.editedCategoryInfo;
      var Id = this.editedCategoryInfo.assetCategoryId;
      this.$http
        .put("/AssetCategory", data)
        .then((response) => {
          if (response.data.isSuccess === true) {
            this.categoryInfo = this.editedCategoryInfo;

            console.log(response.data.outputObject);

            this.$toast.success("Category updated successfully.", "Success", {
              position: "topRight",
            });
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          if (error.response.status) {
            this.$toast.error(" Updating Process Failed", "Error", {
              position: "topRight",
            });
          }
        });
      this.dialogEdit = false;
    },

    getStatusColor(data) {
      switch (data) {
        case "true":
          return "success";
        case "false":
          return "error";
        default:
          return "";
      }
    },
    getSaleStatus(sale) {
      switch (sale) {
        case 1:
          return "";
        default:
          return "d-none";
      }
    },
  },
};
</script>
